import { useEffect, useState } from "react";
import { Form } from "../../../UI/Form";
import { BigNumber, ethers, utils } from "ethers";
import { Input } from "../../../UI/Inputs";
import useContracts from "../../../../contexts/ContractsContext/useContracts";
import { submitAstraMultisigTx } from "../../../../utils/submitAstraMultisigTx";

const Treshold = ({ contract, pair }) => {
  const { provider, contracts } = useContracts();

  const [formData, setFormData] = useState({
    amount: "",
  });
  const [treshold, setTreshold] = useState(null);
  const [tresholdDecimals, setTresholdDecimals] = useState(0);

  useEffect(() => {
    if (utils.isAddress(pair)) {
      contract.provisor(pair).then(async (provisorAddress) => {
        const provisor = new ethers.Contract(
          provisorAddress,
          ["function _THR_100() view returns (uint256)"],
          provider,
        );
        const decimals = (await provisor._THR_100()).toString();
        setTresholdDecimals(decimals);

        const tresholdSlot = BigNumber.from(
          await provider.getStorageAt(provisorAddress, 2),
        ).toString();
        setTreshold((+tresholdSlot / +decimals) * 100);
      });
    } else {
      setTreshold(0);
    }
  }, [pair]);

  const handleData = (event) => {
    const { name, value } = event.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    const { data } = await contract.populateTransaction.setTreshold(
      pair,
      (+formData.amount * +tresholdDecimals) / 100,
    );

    const tx = await submitAstraMultisigTx(contracts, contract.address, data);
    return await tx.wait();
  };

  return (
    <Form handler={handleSubmit} contract={contract}>
      <h3>Method: setTreshold</h3>
      {!!treshold && (
        <p>
          Current treshold: <b>{treshold}%</b>
        </p>
      )}
      <Input
        type="number"
        value={formData.amount}
        setValue={handleData}
        title="Treshold %"
        name="amount"
        placeholder="0%"
        returnEvent
      />
    </Form>
  );
};

export default Treshold;
