import amb from "../assets/svg/amb.svg";
import hbr from "../assets/svg/hbr.svg";
import kos from "../assets/svg/kos.svg";
import ast from "../assets/svg/ast.svg";
import { ContractNames } from "@airdao/airdao-node-contracts";
import { ethers } from "ethers";

export const KOSMOS_MENU = [
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    url: "/fees",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Auctioneers",
    url: "/auctioneers",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Tellers",
    url: "/teller",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Token",
    url: "/token",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Tiers",
    url: "/tiers",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Feeders",
    url: "/feeders",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Reward vault",
    url: "/reward-vault",
  },
  {
    contractName: ContractNames.Ecosystem_BondMarketplaceMultisig,
    label: "Engagement",
    url: "/engagement",
  },
];

export const STAKING_MENU = [
  {
    contractName: ContractNames.Ecosystem_StarfleetMultisig,
    label: "Setup",
    url: "/staking",
  },
];
export const ASTRA_MENU = [
  {
    contractName: ContractNames.Ecosystem_AstradexMultisig,
    label: "Token",
    url: "/astra/token",
  },
  {
    contractName: ContractNames.Ecosystem_AstradexMultisig,
    label: "Staking",
    url: "/astra/staking",
  },
  {
    contractName: ContractNames.Ecosystem_AstradexMultisig,
    label: "Chronos",
    url: "/astra/chronos",
  },
  {
    contractName: ContractNames.Ecosystem_AstradexMultisig,
    label: "Eclipse",
    url: "/astra/eclipse",
  },
];
export const LIQUID_STAKING_MENU = [
  {
    contractName: ContractNames.Ecosystem_LiquidPoolMultisig,
    label: "Liquid staking",
    url: "/liquid-staking",
  },
  {
    contractName: ContractNames.Ecosystem_LiquidPoolMultisig,
    label: "Borrowing",
    url: "/borrowing",
  },
  {
    contractName: ContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig,
    label: "Higher Yielding Pools",
    url: "/harbor",
  },
  {
    contractName: ContractNames.Ecosystem_LimitedTokenPoolsManagerMultisig,
    label: "Token",
    url: "/harbor-token",
  },
];

export const CORE_MENU = [
  {
    contractName: ContractNames.Ecosystem_MasterMultisig,
    url: "/management",
  },
];

export const BANKS_MENU = [
  {
    contractName: ContractNames.Ecosystem_GeneralTokenBank_Multisig,
    url: "/banks",
  },
];

export const explorerApi = {
  30746: "https://explorer-v2-api.ambrosus-dev.io/v2/",
  22040: "https://explorer-v2-api.ambrosus-test.io/v2/",
  16718: "https://explorer-v2-api.ambrosus.io/v2/",
};
export const auctioneersMethods = [
  "setMinDepositInterval(uint48:Interval in seconds)",
  "setMinMarketDuration(uint48:Duration in seconds)",
  "pause()",
  "unpause()",
];

export const SDAAuctioneersMethods = [
  "setDefaults(uint32[6])",
  "setIntervals(uint256:Market id,uint32[3])",
  "pause()",
  "unpause()",
];

export const tellerMethods = [
  "setProtocolFee(uint256*1000:Percents %)",
  "setBeneficiary(address:New address)",
  "setFeeDiscountFeeder(address:New address,address:kos)",
  "setReferrerFee(uint48:Fee)",
  "pause()",
  "unpause()",
];

export const kosTokenMethods = [
  "mint(address:To,uint256*decimals18:Amount)",
  "pause()",
  "unpause()",
  "transferOwnership(address:New owner)",
];

export const stakingTierManagerMethods = [
  "removeTier(address:kos, uint256:Tier level)",
  "transferOwnership(address:Address)",
  "setLockContract(address:Address)",
];

export const discountFeederMethods = [
  "removeDiscount(address:kos,uint256:Tier level)",
  "transferOwnership(address:Address)",
  "setStakingTierManager(address:Address)",
];

export const rewardsFeederMethods = [
  "removeTierRewards(address:kos,uint256:Tier level)",
  "transferOwnership(address:Address)",
  "setStakingTierManager(address:Address)",
];

export const rewardVaultMethods = {
  setter: [
    "setStakingToken(address)",
    "setRewardToken(address)",
    "setRewardFeeder(address)",
    "setClaimWhitelist(address)",
    "revokeClaimWhitelist(address)",
    "cachebackVault(address:Address)",
  ],
  pauser: ["startDistributing()", "stopDistributing()"],
};

export const userBMPurchaserMethods = ["setVaultForClaims(address)"];

export const timeframeOracleMethods = ["setWriter(address)", "reset()"];

export const engagementOracleMethods = ["setWriter(address)", "resetTiers()"];

export const engagementDiscountTierManagerMethods = [
  "clearTiers()",
  "removeTier(uint256:Tier level)",
  "setOracle(address:Address)",
  "transferOwnership(address:Address)",
];

export const astraMethods = ["transferOwnership(address:Address)"];

export const liquidStakingStorageRewardMethods = [
  "withdrawAmb(address:Address, uint256*decimals18:Amount)",
];

export const liquidStakingWithdrawalDelayMethods = [
  "setUnstakeLockTime(uint256*86400:Days)",
];

export const liquidStakingSetMinStake = [
  "setMinStakeValue(uint256*decimals18:Amount)",
];

export const liquidStakingTreasuryFeeMethods = [
  "setFee(uint256*100:Percent %)",
  "withdraw(address:Address to,uint256*decimals18:Amount)",
];
export const liquidStakingTreasuryMethods = [
  "withdraw(address:Address to,uint256*decimals18:Amount)",
];

export const liquidNodesManagerMethods = [
  "setNodeStakeAndCount(uint256*decimals18:Node stake,uint256:Max count)",
];

export const astraTokenMethods = [
  "mint(address:To,uint256*decimals18:Amount)",
  "pause()",
  "unpause()",
  "transferOwnership(address:Address)",
];

export const astraStakingMethods = ["pause()", "unpause()"];

export const astraChronosMethods = [
  "setBeneficiary(address:Address)",
  "pause()",
  "unpause()",
];

export const astraEclipseMethods = [
  "setMathModule(address:Address)",
  "setStaking(address:Address)",
  "setStakingToken(address:Address)",
  "setRouter(address:Address)",
];

export const hbrTokenMethods = [
  "mint(address:Address to,uint256*decimals18:Amount)",
  "pause()",
  "unpause()",
  "transferOwnership(address:Address)",
];

export const orbitLendMethods = [
  "setOracle(address: Address)",
  "pause()",
  "unpause()",
  "deployTreasury(address:Token)",
  "transferOwnership(address)",
  "setLeverageFactor(uint256*10000:Leverage factor)",
];

export const orbitOracleMethods = ["setBackendAddress(address:Address)"];

export const generalBankTokens = [
  {
    symbol: "AMB",
    logoUrl: amb,
    address: {
      30746: ethers.constants.AddressZero,
      22040: ethers.constants.AddressZero,
      16718: ethers.constants.AddressZero,
    },
  },
  {
    symbol: "HBR",
    logoUrl: hbr,
    address: {
      30746: "0xc38A62887B1D04f25Aa05DbdB235C20C6468c554",
      22040: "0x7B58Cbb7c4Ff2E53F8c4405606D0A7AF707ab00b",
      16718: "0xd09270E917024E75086e27854740871F1C8E0E10",
    },
  },
  {
    symbol: "KOS",
    logoUrl: kos,
    address: {
      30746: "0x6116c12421aC75dD3d3066aEFf87b58662978a29",
      22040: "0xAedD2bf3Aa338088C5024f5A92bBc708C0073BF0",
      16718: "0xC15891E4dE2793726c20F53EcA6FB6319968E5F3",
    },
  },
  {
    symbol: "AST",
    logoUrl: ast,
    address: {
      30746: "0xB9F038640f896edfd18169243efBF7659BC21299",
      22040: "0x24f3811961685888c7a1966cAec194e5444bfC0D",
      16718: "0x5ceCBde7811aC0Ed86Be11827AE622b89Bc429DF",
    },
  },
];
export const eclipsePairs = {
  22040: [
    { value: "0xBfa2F9a8E66365249f2418a9c6DebEec83634cA9", label: "USDC/AMB" },
    { value: "0xc9133C01367e4b6B21F8B7fA861B52aF60c9A31A", label: "BOND/AMB" },
  ],
  16718: [
    { value: "0xDeB9F1DE562B4c8994612038C8Ae18B6f833A39e", label: "AMB/BOND" },
    { value: "0x6ee05D1Fe386555D7d4804D4B08141C60bB5eabf", label: "AMB/USDC" },
    { value: "0xC573Cf564995F36a1F8cF92C4F891FE44e64fe24", label: "BOND/USDC" },
    {
      value: "0x846B249b7334377E03983bEDe569C3700039bcDd",
      label: "AMB/Airdoge",
    },
    {
      value: "0xC95188ea34F72a679Cf3c3e981f2f198d4AC6dB3",
      label: "stAMB/USDC",
    },
    { value: "0xf8b78584282ae447052Af488AC2D907c307f0841", label: "stAMB/AMB" },
  ],
  30746: [],
};

export const eclipseTokens = [
  { value: ethers.constants.AddressZero, label: "AMB" },
  { value: "0xFF9F502976E7bD2b4901aD7Dd1131Bb81E5567de", label: "USDC" },
  { value: "0x096B5914C95C34Df19500DAff77470C845EC749D", label: "BOND" },
  { value: "0xd09270E917024E75086e27854740871F1C8E0E10", label: "HBR" },
  { value: "0x5ceCBde7811aC0Ed86Be11827AE622b89Bc429DF", label: "AST" },
  { value: "0xC15891E4dE2793726c20F53EcA6FB6319968E5F3", label: "KOS" },
];
