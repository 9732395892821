import NoMethodChosenPage from "./components/pages/NoMethodChosenPage";
import TransactionsList from "./components/pages/TransactionsList/TransactionsList";
import Management from "./components/pages/management/Management";
import Teller from "./components/pages/kosmos/teller";
import Auctioneers from "./components/pages/kosmos/auctioneers";
import Bond from "./components/pages/kosmos/Bond";
import Token from "./components/pages/kosmos/token";
import Tiers from "./components/pages/kosmos/tiers";
import Feeder from "./components/pages/kosmos/feeder";
import Reward from "./components/pages/kosmos/reward";
import Engagement from "./components/pages/kosmos/engagement";
import Staking from "./components/pages/staking";
import LiquidStaking from "./components/pages/liquidStaking/liquid-staking";
import Chronos from "./components/pages/astra/chronos";
import Eclipse from "./components/pages/astra/eclipse";
import AstraToken from "./components/pages/astra/token";
import AstraStaking from "./components/pages/astra/staking";
import Harbor from "./components/pages/liquidStaking/harbor";
import HarborToken from "./components/pages/liquidStaking/harbor-token";
import Borrowing from "./components/pages/liquidStaking/borrowing";
import Banks from "./components/pages/banks";

export default [
  {
    path: "/",
    element: <NoMethodChosenPage />,
  },
  {
    path: "/confirmation",
    element: <TransactionsList />,
  },
  {
    path: "/management",
    element: <Management />,
  },
  {
    path: "/fees",
    element: <Bond />,
  },
  {
    path: "/auctioneers",
    element: <Auctioneers />,
  },
  {
    path: "/teller",
    element: <Teller />,
  },
  {
    path: "/token",
    element: <Token />,
  },
  {
    path: "/tiers",
    element: <Tiers />,
  },
  {
    path: "/feeders",
    element: <Feeder />,
  },
  {
    path: "/reward-vault",
    element: <Reward />,
  },
  {
    path: "/engagement",
    element: <Engagement />,
  },
  {
    path: "/staking",
    element: <Staking />,
  },
  {
    path: "/astra/token",
    element: <AstraToken />,
  },
  {
    path: "/astra/staking",
    element: <AstraStaking />,
  },
  {
    path: "/astra/chronos",
    element: <Chronos />,
  },
  {
    path: "/astra/eclipse",
    element: <Eclipse />,
  },
  {
    path: "/liquid-staking",
    element: <LiquidStaking />,
  },
  {
    path: "/harbor",
    element: <Harbor />,
  },
  {
    path: "/harbor-token",
    element: <HarborToken />,
  },
  {
    path: "/borrowing",
    element: <Borrowing />,
  },
  {
    path: "/banks",
    element: <Banks />,
  },
];
