import useContracts from "../../../../contexts/ContractsContext/useContracts";
import useUserPermissions from "../../../../contexts/UserPermissionsContext/useUser";
import { Multisig } from "@airdao/airdao-node-contracts";
import { useQuery } from "react-query";
import usePermissions from "../../../../contexts/PermissionsContext/usePermissions";
import formatTransactions from "../logic/formatTransactions";

export default function useTransactions() {
  const { contracts } = useContracts();
  const { groups } = usePermissions();
  const { address } = useUserPermissions();

  const { permissions } = useUserPermissions();

  const getTransactions = async () => {
    if (!permissions || !contracts || !groups || !address)
      return { pending: [], approved: [] };
    const permittedContractsAddresses = permissions.map(
      ({ address }) => address,
    );
    const rawTransactions = await Multisig.getTransactionsFromContracts(
      contracts,
      permittedContractsAddresses,
    );

    const transactions = formatTransactions(
      rawTransactions,
      address,
      groups,
      contracts,
    );
    const pending = transactions.filter(
      (transaction) => !transaction.hasUserConfirmed,
    );
    const approved = transactions.filter(
      (transaction) => transaction.hasUserConfirmed,
    );

    return { pending, approved };
  };

  const { data, refetch, isFetching } = useQuery({
    queryKey: ["transactions", !!contracts, permissions, groups, address],
    queryFn: getTransactions,
    initialData: { pending: [], approved: [] },
  });

  return { data, refetch, isFetching };
}
