export function InputAddress({ title, value, setValue, inputProps = {} }) {
  return (
    <Input
      title={title}
      value={value}
      setValue={setValue}
      inputProps={{
        placeholder: "0x0000000000000000000000000000000000000000",
        ...inputProps,
      }}
    />
  );
}

export function InputAmount({ title, value, setValue, inputProps = {} }) {
  return (
    <InputNumber
      title={title}
      value={value}
      setValue={setValue}
      inputProps={{ placeholder: "AMB", ...inputProps }}
    />
  );
}

export function InputPercent({ title, value, setValue, inputProps = {} }) {
  const handleChange = (value) => {
    if (!(value < 0 || value > 100)) setValue(value);
  };
  return (
    <InputNumber
      title={title}
      value={value}
      setValue={handleChange}
      inputProps={{ placeholder: "%", ...inputProps }}
    />
  );
}

export function InputNumber({
  title,
  value,
  setValue,
  inputProps = {},
  returnEvent,
}) {
  const handleChange = (value) => {
    if (!isNaN(value)) setValue(value);
  };
  return (
    <Input
      title={title}
      value={value}
      setValue={handleChange}
      inputProps={inputProps}
      returnEvent={returnEvent}
    />
  );
}

export function Input({
  title,
  value,
  setValue,
  placeholder,
  name = "",
  inputProps = {},
  className = "",
  returnEvent = false,
  type = "text",
}) {
  const handleChange = (e) => {
    const { value } = e.target;

    if (type === "number" && /[^\d.]/g.test(value)) {
      return;
    }
    setValue(returnEvent ? e : value);
  };

  return (
    <label>
      {title && <span className="page__input-name">{title}</span>}
      <input
        className={`ui-input ${className}`}
        value={value}
        name={name}
        onChange={handleChange}
        placeholder={placeholder}
        {...inputProps}
      />
    </label>
  );
}
